
import { Component, Vue, Model } from 'nuxt-property-decorator'

@Component
export default class NavigationDrawer extends Vue {
  @Model('input') drawer!: boolean

  get userPermissions () {
    return this.$accessor.app.userPermissions || {}
  }

  get incidents_items () {
    return [
      { title: 'active_incidents', icon: 'mdi-alert', link: '/incidents/active', permissions: this.userPermissions?.incident },
      { title: 'finished_incidents', icon: 'mdi-check', link: '/incidents/finished', permissions: this.userPermissions?.incident },
      { title: 'past_incidents', icon: 'mdi-clock', link: '/incidents/past', permissions: this.userPermissions?.incident },
      { title: 'repatriation_incidents', icon: 'mdi-train-car-flatbed-car', link: '/incidents/repatriation', permissions: this.userPermissions?.incident }
    ]
  }

  get inspection_items () {
    return [
      { title: 'inspection_reviews', icon: 'mdi-eye-check-outline', link: '/inspection/reviews', permissions: this.userPermissions?.inspection_review }
    ]
  }

  get repatriation_incidents_items () {
    return [
      { title: 'active_incidents', icon: 'mdi-alert', link: '/repatriation-incidents/active', permissions: this.userPermissions?.active_repatriation },
      { title: 'finished_incidents', icon: 'mdi-check', link: '/repatriation-incidents/finished', permissions: this.userPermissions?.active_repatriation },
      { title: 'past_incidents', icon: 'mdi-clock', link: '/repatriation-incidents/past', permissions: this.userPermissions?.active_repatriation }
    ]
  }

  get item_set_first () {
    return [
      {
        title: 'mobile_camera',
        icon: 'mdi-car-wireless',
        link: '/mobile-camera',
        permissions: this.userPermissions.mobile_camera,
        getPermission: () => {
          return this.userPermissions.mobile_camera?.includes('init_block')
        }
      },
      { title: 'subscriptions', icon: 'mdi-account-credit-card', link: '/subscription', permissions: this.userPermissions.subscription },
      { title: 'business_subscriptions', icon: 'mdi-office-building', link: '/business-subscription', permissions: this.userPermissions.business_subscription },
      { title: 'fine', icon: 'mdi-file-document-alert-outline', link: '/fines', permissions: this.userPermissions.fine },
      {
        title: 'traffic_monitoring',
        icon: 'mdi-traffic-light',
        link: '/traffic-monitoring',
        permissions: this.userPermissions.mobile_camera,
        getPermission: () => {
          return this.userPermissions.mobile_camera?.includes('view')
        }
      }
    ]
  }

  get payments_items () {
    return [
      { title: 'parking', icon: 'mdi-parking', link: '/payments/parking', permissions: this.userPermissions?.payment_parking },
      { title: 'subscriptions', icon: 'mdi-account-credit-card', link: '/payments/subscriptions', permissions: this.userPermissions?.payment_subscription },
      { title: 'fines', icon: 'mdi-file-document-alert-outline', link: '/payments/fines', permissions: this.userPermissions?.payment_fine },
      {
        title: 'tickets_and_cards',
        icon: 'mdi-account-credit-card',
        children: [
          { title: 'daily_card', icon: 'mdi-card-bulleted', link: '/daily-card', permissions: this.userPermissions.daily_card },
          { title: 'tickets', icon: 'mdi-ticket', link: '/tickets', permissions: this.userPermissions.tickets }
        ]
      }
    ]
  }

  get statistic_items () {
    return [
      { title: 'inspector_activity', icon: 'mdi-walk', link: '/reports/inspector-activity', permissions: this.userPermissions?.inspector_activity },
      { title: 'tyreblocker_activity', icon: 'mdi-car-tire-alert', link: '/reports/tyreblocker-activity', permissions: this.userPermissions?.tyreblocker_activity },
      { title: 'repatriator_activity', icon: 'mdi-car-tire-alert', link: '/reports/repatriator-activity', permissions: this.userPermissions?.repatriator_activity },
      { title: 'camera_activity', icon: 'mdi-cctv', link: '/reports/camera-activity', permissions: this.userPermissions?.camera_statistics },
      { title: 'mobile_camera_activity', icon: 'mdi-car-wireless', link: '/reports/mobile-cam', permissions: this.userPermissions?.camera_statistics },
      {
        title: 'mobile_camera_tracker',
        icon: 'mdi-track-light',
        link: '/mc-tracker',
        permissions: this.userPermissions.mobile_camera,
        getPermission: () => {
          return this.userPermissions.mobile_camera?.includes('view')
        }
      },
      { title: 'payment_statistics', icon: 'mdi-currency-usd', link: '/statistics/payments', permissions: this.userPermissions.payment_statistic },
      // { title: 'payment_statistics_details', icon: 'mdi-currency-usd', link: '/statistics/payments/details', permissions: [] },
      { title: 'store', icon: 'mdi-store', link: '/statistics/store', permissions: this.userPermissions.store_statistic },
      { title: 'sms', icon: 'mdi-message', link: '/sms', permissions: this.userPermissions.sms }
    ]
  }

  get employees_reports_items () {
    return [
      { title: 'monthly_statistics', icon: 'mdi-chart-line', link: '/employee-reports/monthly', permissions: this.userPermissions.payment_statistic },
      { title: 'monthly_employees_statistics', icon: 'mdi-chart-line', link: '/employee-reports/monthly-employees', permissions: this.userPermissions.payment_statistic }
    ]
  }

  get settings_items () {
    return [
      { title: 'cities', icon: 'mdi-home-city', link: '/city', permissions: this.userPermissions.city },
      { title: 'park_zone', icon: 'mdi-parking', link: '/park-zone', permissions: this.userPermissions.admin_user },
      { title: 'park_area', icon: 'mdi-map', link: '/park-area', permissions: this.userPermissions.admin_user },
      { title: 'mc_park_area', icon: 'mdi-car-wireless', link: '/mc-park-area', permissions: this.userPermissions.admin_user },
      { title: 'comments', icon: 'mdi-comment', link: '/comments', permissions: this.userPermissions.address_note },
      { title: 'non_working_days', icon: 'mdi-palm-tree', link: '/non-working-days', permissions: this.userPermissions.non_working_days },
      { title: 'fine_type', icon: 'mdi-view-dashboard', link: '/fine-type', permissions: this.userPermissions.fine_type },
      { title: 'subscription_plan', icon: 'mdi-cash-sync', link: '/subscription-plan', permissions: this.userPermissions.subscription_plan },
      { title: 'discount', icon: 'mdi-sale-outline', link: '/discount', permissions: this.userPermissions.discount },
      {
        title: 'translations',
        icon: 'mdi-earth',
        children: [
          { title: 'translations_city', link: '/translations-city', permissions: this.userPermissions.translation },
          { title: 'translations_common', link: '/translations-common', permissions: this.userPermissions.translation }
        ]
      },
      { title: 'roles_and_permissions', icon: 'mdi-security', link: '/role', permissions: this.userPermissions.role }
    ]
  }

  get item_set_second () {
    return [
      { title: 'whitelist_car', icon: 'mdi-car', link: '/whitelist-car', permissions: this.userPermissions.whitelist_car }
    ]
  }

  get trade_network () {
    return [
      { title: 'company', icon: 'mdi-domain', link: '/company', permissions: this.userPermissions.company },
      { title: 'store', icon: 'mdi-store', link: '/store', permissions: this.userPermissions.store }
    ]
  }

  get users () {
    return [
      { title: 'employees', icon: 'mdi-account', link: '/user-management', permissions: this.userPermissions.admin_user },
      { title: 'cameras', icon: 'mdi-cctv', link: '/camera-management', permissions: this.userPermissions.camera }
    ]
  }

  checkActiveSubLink (linksArr: any) {
    const result = linksArr.find((el: any) => {
      if (!el.children) {
        return el.link && this.$route.path === this.localePath(el.link)
      } else {
        const childResult = el.children.find((subEl: any) => {
          return subEl.link && this.$route.path.includes(this.localePath(subEl.link))
        })

        return childResult
      }
    })

    return !!result
  }
}

